import React, { useState, useEffect } from "react";
import axios from "axios";

import { Layout, Row, Col, message, Button } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

import { navigate } from "../utils";

const { Content } = Layout;

export default function MonEspaceRider() {
  const [signatureId, setSignatureId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return navigate("/login-mon-espace-rider");
    }
    async function getRiderData() {
      setIsLoading(true);
      axios
        .get(process.env.GATSBY_API_URL + "/access-rider-space-data", {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response?.data.signature_id) {
            setSignatureId(response.data.signature_id);
          }
        })
        .catch((e) => {
          message.error(
            "Expiration de votre session, merci de vous reconnecter"
          );
          localStorage.clear();
          return navigate("/login-mon-espace-rider");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    getRiderData();
  }, []);

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Bienvenue dans ton espace rider</h2>
            <Row>
              <Col
                lg={{ span: 10, offset: 7 }}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <Button href={`mailto:sinistre@patinette.io?subject=Déclaration d'un sinistre`} style={{ marginBottom: "2em" }} size="large">
                  Déclarer un sinistre
                </Button>
                <br />
                {signatureId && (
                  <Button
                    type="primary"
                    target="_blank"
                    size="large"
                    href={`${process.env.GATSBY_API_URL}/download/signature/${signatureId}`}
                  >
                    Télécharger mon contrat Patinette
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
